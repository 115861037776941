import Link from "next/link";
import Image from "next/image";
import styles from "../12-Marketplace/market.module.scss"

import useCategoryCount from "@/context/useCategoryCount";

import CourseDetails from "../../data/course-details/courseData.json";

const CategoryEight = ({categories}) => {

  return (
    <>
      {categories?.slice(0, 8)?.map((item, innerIndex) => {
          return (
            <div className={`${styles["container"]}`} key={innerIndex}>
            <Link
              className="rbt-cat-box"
              href={`/courses/category/${item?.id}`}
            >
              <div className="inner text-center">
                  <h5 className={`${styles["text-card"]}`}>{item.categoryName}</h5>
              </div>
            </Link>
          </div>
          );
        })}
    </>
  );
};

export default CategoryEight;
