import Link from "next/link";
import Image from "next/image";
import CategoryEight from "../Category/CategoryEight";
import Card from "../Cards/Card";
import CounterFive from "../Counters/Counter-Five";
import TestimonialFive from "../Testimonials/Testimonial-Five";
import BlogGridMinimal from "../Blogs/BlogGridMinimal";
import NewsletterFour from "../Newsletters/Newsletter-Four";
import Hero from "../../public/images/gallery/hero-image.webp";
import { useQuery } from "@apollo/client";
import { FETCH_ALL_CATEGORIES } from "@/graphql/querries/categories";
import { FETCH_ALL_COURSES } from "@/graphql/querries/courses";
import styles from "./market.module.scss";
import { useEffect, useRef, useState } from "react";
import posterImage from "../../public/images/gallery/thumail.jpg";




const Marketplace = () => {
  const { data } = useQuery(FETCH_ALL_CATEGORIES);
  const { data: AllCourses } = useQuery(FETCH_ALL_COURSES, {
    variables: {
      paginationArgs: {
        pageSize: 6,
        pageNumber: 1,
      },
    },
  });


  const videoRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current) {
        const videoPosition = videoRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (videoPosition < windowHeight && videoPosition > 0) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
        console.log(videoPosition < windowHeight && videoPosition > 0);
      }
    };
    

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const video = "https://blog.mentorbliss.com/wp-content/uploads/2024/11/Mentorbliss-2.mp4"

  return (
    <>
      <div
        className={`rbt-banner-5 height-650  ${styles["bg_images"]}`} 
      >
        <div className={`container ${styles["cover_bg"]}`} style={{ display: "flex" }}>
          <div className="d-flex flex-column align-items-center">
            <div
              className="col-lg-9"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div //inner
                className="d-flex flex-column align-items-center"
              >
                <h2 className={`text-center cus-text text-light ${styles["heading-query"]}`}>
                  Unlock Growth with Mentors
                </h2>
                <p className={`text-center text-light cus-para mx-auto ${styles["text-query"]}`} style={{ width: "80%" }} >
                  Mentorbliss is a new education platform where you can access lessons and resources from expert mentors to help you grow in your career and business.
                </p>
                <div className="slider-btn rbt-button-group justify-content-start">
                  <Link
                    className="rbt-btn btn-border icon-hover color-white rounded-pill" //radius-round
                    href="/courses"
                  >
                    <span className="btn-text">Explore Courses</span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                  </Link>
                  <Link
                    className="rbt-btn-link color-white rounded"
                    href="/create-account"
                  >
                    Start learning<i className="feather-arrow-right"></i>
                  </Link>
                </div>
                <div className={`${styles["absolute-video"]} ${styles["bg"]}`}>
                  <video muted={false} ref={videoRef} poster="https://henryodia.com/wp-content/uploads/2024/09/thumail.jpg" className={`${styles["video"]}`}>
                    <source src={video} type="video/mp4">
                    </source>
                  </video>
                </div>
              </div>&apos;
            </div>
          </div>
        </div>
      </div>

      <div className={`rbt-category-area bg-color-white ${styles["from-top"]}`} style={{ paddingTop: "3rem", marginTop: "35rem" }}>
        <div className={`container ${styles["row-container"]}`}>
          <div className={`${styles["row-change"]}`}>
            <CategoryEight categories={data?.fetchCourseCategories} />
          </div>
        </div>
      </div>

      <div className="rbt-featured-course bg-color-white mb--70" style={{ paddingTop: "3rem" }}>
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Featured Courses</h2>
                <p className="description mt--20">
                  Join other students making a living with skills they have
                  learnt from Mentorbliss. Learn skills you can sell to anyone
                  anywhere in the world with ease. Learn at your own time and
                  pace!
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link
                  className="rbt-btn btn-border icon-hover rounded-1" //radius-round
                  href="/courses"
                >
                  <span className="btn-text">Browse More Courses</span>
                  <span className="btn-icon">
                    <i className="feather-arrow-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className="row g-5">
            <Card courses={AllCourses?.fetchAllCourses} />
          </div>
        </div>
      </div>

      <div
        className="rbt-counterup-area bg_image_fixed ptb--170 bg-black-overlay"
        data-black-overlay="2"
        style={{
          backgroundImage: "url(/images/bg/hero-image.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <CounterFive />
      </div>

      <div className="rbt-featured-course bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Why Learning With us?</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link
                  className="rbt-btn btn-border icon-hover rounded-1" //radius-round
                  href="/courses"
                >
                  <span className="btn-text">Browse More Courses</span>
                  <span className="btn-icon">
                    <i className="feather-arrow-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="d-flex gap-5">
                <Image
                  width={200}
                  height={200}
                  src={`/images/icons/teachers.svg`}
                  alt="mentores"
                />

                <div className="mt--40">
                  <h5 className="title">World-Class Mentors</h5>
                  <p className="description">
                    On Mentorbliss, you will learn from world-class mentors who
                    are change-makers in their professions and fields.
                  </p>
                </div>
              </div>
              <div className="d-flex gap-5">
                <Image
                  width={150}
                  height={150}
                  src={`/images/icons/courses.svg`}
                  alt="courses"
                />

                <div className="mt--40">
                  <h5 className="title">Practical Lessons</h5>
                  <p className="description">
                    We take you beyond theory and talks. Our lessons are
                    practical and straight to doing!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="d-flex gap-5">
                <Image
                  width={200}
                  height={200}
                  src={`/images/icons/communities.svg`}
                  alt="cpmmunities"
                />

                <div className="mt--40">
                  <h5 className="title">Global Students Community</h5>
                  <p className="description">
                    Be part of highly inspired and mentored learners from across
                    the world. Our community is structured to help you grow.
                  </p>
                </div>
              </div>
              <div className="d-flex gap-5">
                <Image
                  width={200}
                  height={200}
                  src={`/images/icons/support.svg`}
                  alt="support"
                />

                <div className="mt--40">
                  <h5 className="title">Step-by-Step Curriculum</h5>
                  <p className="description">
                    Our courses are structured to first inform you and then
                    implementing the knowledge you have acquired.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-testimonial-area bg-color-extra2 rbt-section-gap">
        <div className="container">
          <div className="testimonial-item-3-activation swiper rbt-arrow-between gutter-swiper-30">
            <TestimonialFive isDesc={true} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketplace;
